import { graphql } from "gatsby";
import React from "react";

import Comments from "../components/comments";
import Layout from "../components/layout";
import getAuthor from "../logic/get-author";
import { getKeywords, keywordsRegex } from "../logic/keywords";
import PageThumbs from "../components/page-thumbs";
import replaceVideos from "../logic/replaceVideos";
import replaceFootnotes from "../logic/replaceFootnotes";
import SEO from "../components/seo";
import BlogArticle from "../components/blog-article";

const Post = ({
  data: { avatar, backdrop, logo, post, site },
  location,
  pageContext
}) => {
  let newHtml = replaceVideos(post.childMarkdownRemark.html);

  let keywords = getKeywords(newHtml);
  newHtml = newHtml
    .replace(keywordsRegex, "")
    .replace(/<h1>.+?<\/h1>/, "")
    .replace(/<h2>/g, `<h2 style="font-size: 1.25rem">`);

  const postAuthor = getAuthor(post.owner);

  const postData = {
    author: postAuthor,
    createdDate: post.created_date,
    image: post.ogImg,
    imageAlt: post.ogImgAlt,
    isBlogPost: true,
    modifiedDate: post.last_updated_date,
    keywords: keywords,
    url: location.pathname
  };

  const formattedTitle = replaceFootnotes(post.title);

  return (
    <Layout
      avatar={avatar}
      backdrop={backdrop}
      location={location}
      commentsWrapper={true}
      site={site}
    >
      <SEO
        avatar={avatar}
        logo={logo}
        description={post.childMarkdownRemark.excerpt.replace(post.title, "")}
        postData={postData}
        site={site}
        title={`${formattedTitle} - ${site.siteMetadata.title}`}
      />
      <BlogArticle
        avatar={avatar}
        logo={logo}
        html={newHtml}
        isPostPage={true}
        post={post}
        title={formattedTitle}
      />
      <PageThumbs pageContext={pageContext} />
      <Comments
        title={post.title}
        postId={post.fields.slug.substring(
          0,
          //remove trailing slash, disqus is already keyed without it
          post.fields.slug.length - 1
        )}
      />
    </Layout>
  );
};
export default Post;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    post: dropboxPaperDocumentNew(
      fields: { slug: { eq: $slug }, status: { eq: "active" } }
    ) {
      ...BlogPostFields
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;
