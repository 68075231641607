import React from "react";
import { renderToString } from "react-dom/server";

import Video from "../components/video";

const videoMarkdownRegex = /<p>\[video .+?\]<\/p>/g;

const replaceVideos = html => {
  const videos = html.match(videoMarkdownRegex);
  if (!videos) {
    return html;
  }

  for (let index = 0; index < videos.length; index++) {
    const video = videos[index];
    const videoLocations = video.match(/https:.+?\]/);
    if (videoLocations && videoLocations.length > 0) {
      const videoLocation = videoLocations[0].substring(
        0,
        videoLocations[0].length - 1
      );
      html = html.replace(video, renderToString(<Video src={videoLocation} />));
    }
  }

  return html.replace(videoMarkdownRegex, renderToString());
};

export default replaceVideos;
export { videoMarkdownRegex };
