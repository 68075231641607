import { DiscussionEmbed } from "disqus-react";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import isDraftPost from "../logic/isDraftPost";

const CommentsWrapper = styled.div`
  background-color: #fff;
  height: 50px;
  position: absolute;
  margin-top: -74px;
  width: 800px;

  @media (max-width: ${Constants.mobileWidth}) {
    width: 335px;
  }
`;

const disqusShortname = `sheandjim`;

const Comments = ({ title, postId }) => {
  return isDraftPost(title, true) ? null : (
    <>
      <DiscussionEmbed
        shortname={disqusShortname}
        config={{ identifier: postId, title: title }}
      />
      <CommentsWrapper />
    </>
  );
};

export default Comments;
