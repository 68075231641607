import React from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  padding-bottom: 56.338%;
  height: 0px;
  position: relative;
  width: 100%;
`;

const Iframe = styled.iframe`
  height: 100%;
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const Video = ({ src }) => {
  return (
    <VideoWrapper>
      <Iframe
        src={src}
        frameborder="0"
        width="100%"
        height="100%"
        allowfullscreen={true}
      />
    </VideoWrapper>
  );
};

export default Video;
